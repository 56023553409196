/**
 * Import and use this `BaseApi` whenever you need a component to use
 * either `CloudApi` from the 'ikonix-front' app (website) or `OfflineApi`
 * from the 'offline-app' depending on which app is running.
 *
 * By using `provide` and `useClass` in the provides of `app.module.ts` and `offline.module.ts`
 * Angular's DI system will provide the indicated implementation.
 *
 * Both `CloudApi` and `OfflineApi` extends from `BaseApi`.
 */
export abstract class BaseApi {
  abstract get base(): string;

  abstract get login(): string;
  abstract get company(): string;
  abstract get equipments(): string;
  abstract get eecEquipments(): string;
  abstract get testFiles(): string;
  abstract get reports(): string;
  abstract get reportTemplates(): string;
  abstract get reportLogo(): string;
  abstract get scannerParameter(): string;
}
