import { environment } from '../../environments/environment';

export class DesktopApi {
  static desktop = environment.desktopApp;

  static socketServer = `${this.desktop}`;
  static hostname = `${this.desktop}/api/common/hostname`;
  static devices = `${this.desktop}/api/equipment`;
  static scan = `${this.desktop}/api/equipment/scan`;
  static active = `${this.desktop}/api/equipment/active`;
  static connect = `${this.desktop}/api/equipment/connect`;
  static checkEquipment = `${this.desktop}/api/equipment/check-equipment`;
  static disconnect = `${this.desktop}/api/equipment/disconnect`;
  static refreshState = `${this.desktop}/api/equipment/refresh`;
  static setMainEquipment = `${this.desktop}/api/perform/set-main-equipment`;
  static failStop = `${this.desktop}/api/perform/fail-stop`;
  static singleStep = `${this.desktop}/api/perform/single-step`;
  static setReportId = `${this.desktop}/api/perform/set-report-id`;
  static setTest = `${this.desktop}/api/perform/set-test`;
  static setStep = `${this.desktop}/api/perform/set-step`;
  static setScanner = `${this.desktop}/api/perform/set-scanner`;
  static setScannerTest = `${this.desktop}/api/perform/set-scanner-test`;
  static runTest = `${this.desktop}/api/perform/test`;
  static runScannerTest = `${this.desktop}/api/perform/scanner-test`;
  static reset = `${this.desktop}/api/perform/reset`;
  static resetTestAllEquipment = `${this.desktop}/api/perform/resetTestAllEquipment`;
  static scannerReset = `${this.desktop}/api/perform/scanner-reset`;
  static storeTest = `${this.desktop}/api/test/remember`;
  static equipmentTests = `${this.desktop}/api/test`;
  static testMemoryType = `${this.desktop}/api/test/memory-type`;
  static generateTestFile = `${this.desktop}/api/test/localtest`;
  static uploadReport = `${this.desktop}/api/test/localreport`;
  static manualScan = `${this.desktop}/api/equipment/manualscan`;

  //EEC
  static eecDevices = `${this.desktop}/api/eec/equipment`;
  static eecScan = `${this.desktop}/api/eec/equipment/scan`;
  static eecActive = `${this.desktop}/api/eec/equipment/active`;
  static eecConnect = `${this.desktop}/api/eec/equipment/connect`;
  static eecCheckEquipment = `${this.desktop}/api/eec/equipment/check-equipment`;
  static eecDisconnect = `${this.desktop}/api/eec/equipment/disconnect`;
  static eecRefreshState = `${this.desktop}/api/eec/equipment/refresh`;

  static eecReset = `${this.desktop}/api/eec/perform/reset`;
  static eecSetTest = `${this.desktop}/api/eec/perform/set-test`;
  static eecRunTest = `${this.desktop}/api/eec/perform/test`;
  static eecSetMainEquipment = `${this.desktop}/api/eec/perform/set-main-equipment`;
}
