import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SubscriptionActiveGuard } from './guards/subscription-active.guard';
import { CompanyInactiveComponent } from './company-inactive/company-inactive.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, SubscriptionActiveGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'troubleshoot',
    loadChildren: () => import('./troubleshoot/troubleshoot.module').then((m) => m.TroubleshootModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthGuard],
    data: {
      only: ['Guest'],
      redirectTo: '/equipment',
    },
  },
  {
    path: 'company-inactive',
    component: CompanyInactiveComponent,
    canActivate: [AuthGuard],
    data: {
      except: ['Guest', 'SuperAdmin'],
    },
  },
  {
    path: 'equipment',
    loadChildren: () => import('./equipment/equipment.module').then((m) => m.EquipmentModule),
    canActivate: [AuthGuard, SubscriptionActiveGuard],
    data: {
      except: ['Guest', 'SuperAdmin'],
    },
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard, SubscriptionActiveGuard],
    data: {
      except: ['Guest', 'SuperAdmin'],
    },
  },
  {
    path: 'tests',
    loadChildren: () => import('./tests/tests.module').then((m) => m.TestsModule),
    canActivate: [AuthGuard, SubscriptionActiveGuard],
    data: {
      except: ['Guest', 'SuperAdmin'],
    },
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard, SubscriptionActiveGuard],
    data: {
      except: ['Guest', 'SuperAdmin'],
    },
  },
  {
    path: 'download',
    loadChildren: () => import('./download/download.module').then((m) => m.DownloadModule),
    canActivate: [AuthGuard, SubscriptionActiveGuard],
    data: {
      except: ['Guest', 'SuperAdmin'],
    },
  },
  {
    path: 'my-account',
    loadChildren: () => import('./my-account/my-account.module').then((m) => m.MyAccountModule),
    canActivate: [AuthGuard],
    data: {
      except: ['Guest'],
    },
  },
  {
    path: 'company-profile',
    loadChildren: () => import('./company-profile/company-profile.module').then((m) => m.CompanyProfileModule),
    canActivate: [AuthGuard],
    data: {
      only: ['Owner'],
    },
  },
  {
    path: 'user-guide',
    loadChildren: () => import('./user-guide/user-guide.module').then((m) => m.UserGuideModule),
    canActivate: [AuthGuard],
    data: {
      except: ['Guest', 'SuperAdmin'],
    },
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      only: ['SuperAdmin'],
    },
  },
  {
    path: 'control-panel',
    loadChildren: () => import('./control-panel/control-panel.module').then((m) => m.ControlPanelModule),
    canActivate: [AuthGuard, SubscriptionActiveGuard],
    data: {
      except: ['Guest'],
    },
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
