import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { EecActiveEquipmentState, EecActiveEquipmentStore } from './eec-active-equipment.store';

@Injectable({ providedIn: 'root' })
export class EecActiveEquipmentQuery extends Query<EecActiveEquipmentState> {
  data$ = this.select('data');

  constructor(protected store: EecActiveEquipmentStore) {
    super(store);
  }
}
