import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface EecInfo {
  firmware: string;
  manufacturer: string;
  model_number: string;
  serial_number: string;
  model: string;
  connectionType: string;
  address: string;
  main: boolean;
  calibrationDate: string;
}

export interface EecStep {
  result: string;
  step: number;
  stepType: string;
  [key: string]: any;
}

export interface EecEquipmentTest {
  steps: EecStep[];
  stepsCount: number;
  id: number;
  name: string;
  dbInfo?: any;
}

export interface EecResult {
  step: number;
  stepStartedAt: Date;
  voltage: string;
  voltage_ac: string;
  voltage_dc: string;
  current: string;
  curren_ac: string;
  current_dc: string;
  frequency: string;
  power: string;
  pf: string;
  a_peak: string;
  reactive: string;
  crest_factor: string;
  apparent: string;
}

export interface EecTestProgress {
  interlock: boolean;
  testInProgress: boolean;
  testFinished: boolean;
  currentStep: number;
  selectedFile: number;
  selectedStep: number;
  overallStatus: string;
  results: EecResult[];
  testStartedAt?: string;
  testedStep: number;
  resetStep: any;
  eventStatuses: any[];
}

export interface EecEquipmentState {
  connected: boolean;
  connectionPort: string;
  info: EecInfo;
  testProgress: EecTestProgress;
}

export function createInitialState(): EecEquipmentState {
  return {
    connected: false,
    connectionPort: null,
    info: null,
    testProgress: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'eec-main-instrument' })
export class EecMainEquipmentStore extends Store<EecEquipmentState> {
  constructor() {
    super(createInitialState());
  }
}
