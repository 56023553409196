import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { DesktopUpdateService } from './services/desktop-update.service';
import { UpdateService } from './services/update.service';
import { UpdateModalComponent } from './shared/update-modal/update-modal.component';
import { OnboardingService } from './ui/onboarding/onboarding.service';
import { AuthQuery } from './auth/state/auth.query';
import { DesktopService } from './state/desktop.service';
import { MainEquipmentService } from './state/main-equipment.service';
import { InstrumentsService } from './equipment/state/instruments.service';
import { CompanyService } from './state/company.service';
import { ActiveEquipmentService } from './state/active-equipment.service';
import { ScannerSetupService } from './services/scanner-setup.service';
import { CommandLogService } from './ui/command-log/command-log.service';
import { TestQueueService } from './state/test-queue.service';
import { environment } from '../environments/environment';
import { EecActiveEquipmentService } from './state/eec-active-equipment.service';
import { EecMainEquipmentService } from './state/eec-main-equipment.service';

@Component({
  selector: 'ik-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  private dialogRef: MatDialogRef<UpdateModalComponent>;

  constructor(
    private update: UpdateService,
    private desktopUpdateService: DesktopUpdateService,
    private dialog: MatDialog,
    private router: Router,
    translate: TranslateService,
    onboardingService: OnboardingService,
    private authQuery: AuthQuery,
    private desktopService: DesktopService,
    private mainEquipmentService: MainEquipmentService,
    private activeEquipmentService: ActiveEquipmentService,
    private eecMainEquipmentService: EecMainEquipmentService,
    private eecActiveEquipmentService: EecActiveEquipmentService,
    private instrumentsService: InstrumentsService,
    private companyService: CompanyService,
    private scannerSetupService: ScannerSetupService,
    private commandLogService: CommandLogService,
    private testQueueService: TestQueueService
  ) {
    this.desktopService.start().subscribe();
    this.mainEquipmentService.start().subscribe();
    this.activeEquipmentService.start().subscribe();
    this.eecMainEquipmentService.start().subscribe();
    this.eecActiveEquipmentService.start().subscribe();
    this.testQueueService.start().subscribe();

    if (environment.showCommandLog) {
      this.commandLogService.start().subscribe();
    }

    this.startOnboarding(onboardingService);

    translate.setDefaultLang('en-US');
    translate.use('en-US');

    this.desktopUpdateService.init();
    this.router.events.pipe(filter((ev) => ev instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      window.scroll(0, 0);
      window.dispatchEvent(new Event('resize'));
    });
    this.desktopUpdateService.openModal$.pipe(distinctUntilChanged()).subscribe((res) => {
      if (!res && this.dialogRef) {
        this.dialogRef.close();
        this.dialogRef = null;
      }
      if (res && this.dialogRef) {
        return;
      }
      if (res) {
        this.dialogRef = this.dialog.open(UpdateModalComponent, {
          width: '500px',
          closeOnNavigation: false,
          disableClose: true,
        });
      }
    });
  }

  private startOnboarding(onboardingService: OnboardingService) {
    let onboardingSubscription: Subscription;
    this.authQuery.loggedIn$.pipe(distinctUntilChanged(), startWith(false)).subscribe((loggedIn) => {
      if (loggedIn) {
        onboardingSubscription = onboardingService.start();
        this.instrumentsService.load().subscribe();
        this.companyService.getData().subscribe();
        const userId = this.authQuery.getValue().user.id;
        this.scannerSetupService.initScannerSetup(userId);
      } else {
        if (onboardingSubscription) {
          onboardingSubscription.unsubscribe();
        }
      }
    });
  }
}
