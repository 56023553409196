import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { DesktopApi } from '../helpers/desktop.api';
import { EquipmentState } from '../state/main-equipment.store';
import { EquipmentIDN } from '@ik/shared-data/schema';
import { BaseApi } from '@ik/shared-data/base.api';
import { EecMainEquipmentQuery } from '@app/state/eec-main-equipment.query';

@Injectable({
  providedIn: 'root',
})
export class EecEquipmentService {
  private connectingSubject = new BehaviorSubject<boolean>(false);
  public connecting = this.connectingSubject.asObservable();

  private connectingObjSubject = new BehaviorSubject<any>(null);
  public connectingObj = this.connectingObjSubject.asObservable();

  private runningSubject = new BehaviorSubject<boolean>(false);
  public running = this.runningSubject.asObservable();

  private scanningSubject = new BehaviorSubject<any>(false);
  public scanning = this.scanningSubject.asObservable();
  private _scanning: boolean = false;

  constructor(
    private http: HttpClient,
    private baseApi: BaseApi,
    private eecMainEquipmentQuery: EecMainEquipmentQuery
  ) {}

  private getMainEquipmentInfo(): EquipmentIDN {
    const mainEquipment = this.eecMainEquipmentQuery.getValue();
    return {
      equipmentModel: mainEquipment.info.model,
      connectionType: mainEquipment.info.connectionType,
      address: mainEquipment.info.address,
      isEecModel: true,
    };
  }

  connect(data: EquipmentIDN) {
    return this.http
      .post(DesktopApi.eecConnect, {
        equipmentInfo: data,
      })
      .pipe(
        catchError((err) => {
          this.setConnection(false);
          return throwError(() => err);
        })
      );
  }

  disconnect(data: EquipmentIDN) {
    return this.http.post(DesktopApi.eecDisconnect, {
      equipmentInfo: data,
    });
  }

  setMainEquipment(data: EquipmentIDN) {
    return this.http.post(DesktopApi.eecSetMainEquipment, {
      equipmentInfo: data,
    });
  }

  setTest() {
    return this.http.post(DesktopApi.eecSetTest, {
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  test(mainEq: EquipmentIDN, isPrompt: boolean) {
    this.runningSubject.next(true);
    return this.http.post(DesktopApi.eecRunTest, {
      mainEqInfo: mainEq,
      eecEqInfo: this.getMainEquipmentInfo(),
      isPrompt,
    });
  }

  reset() {
    this.runningSubject.next(false);
    return this.http.post(DesktopApi.eecReset, {
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  remove(id: number): Observable<any> {
    // TODO: Use `${this.baseApi.eecEquipments}/connect` when support full function eec model
    return this.http.delete(`${this.baseApi.equipments}/${id}`);
  }

  public getDevices(equipmentType: string, connectionType: string): Observable<any[]> {
    return this.http.get<any[]>(DesktopApi.eecDevices, {
      params: {
        equipmentModel: equipmentType,
        connectionType: connectionType,
      },
    });
  }

  public logConnected() {
    return this.eecMainEquipmentQuery.info$.pipe(
      take(1),
      switchMap((info) => {
        if (!info) {
          return of(null);
        }

        // TODO: Use `${this.baseApi.eecEquipments}/connect` when support full function eec model
        return this.http.post(`${this.baseApi.equipments}/connect`, {
          serial_number: info.serial_number,
          scanner_status: 0,
        });
      })
    );
  }

  public setConnection(value: boolean, connection?: any) {
    this.connectingSubject.next(value);
    if (connection) {
      this.connectingObjSubject.next(connection);
    } else {
      this.connectingObjSubject.next(null);
    }
  }

  public setScanning(value: boolean) {
    this.scanningSubject.next(value);
    this._scanning = value;
  }

  updateEquipmentInfo(id: any, body: { calibration_date?: string; scanner_status?: number; name?: string }) {
    // TODO: Use `${this.baseApi.eecEquipments}/connect` when support full function eec model
    return this.http.patch(`${this.baseApi.equipments}/${id}`, { equipment: body });
  }
}
