import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, mapTo, switchMap, take } from 'rxjs/operators';
import { DesktopApi } from '../helpers/desktop.api';
import { DesktopQuery } from '../state/desktop.query';
import { InstrumentsQuery } from '../equipment/state/instruments.query';
import { MainEquipmentQuery } from '../state/main-equipment.query';
import { ActiveEquipmentQuery } from '../state/active-equipment.query';
import { EquipmentState, Info } from '../state/main-equipment.store';
import { ScannerSetup } from './scanner-setup.service';
import { EquipmentIDN } from '@ik/shared-data/schema';
import { AppConfig } from '@ik/shared-data/app.config';
import { BaseApi } from '@ik/shared-data/base.api';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CustomSnackbarComponent } from '@app/shared/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  private connectingSubject = new BehaviorSubject<boolean>(false);
  public connecting = this.connectingSubject.asObservable();

  private connectingObjSubject = new BehaviorSubject<any>(null);
  public connectingObj = this.connectingObjSubject.asObservable();

  private runningSubject = new BehaviorSubject<boolean>(false);
  public running = this.runningSubject.asObservable();

  private scanningSubject = new BehaviorSubject<any>(false);
  public scanning = this.scanningSubject.asObservable();
  private _scanning: boolean = false;

  constructor(
    private http: HttpClient,
    private baseApi: BaseApi,
    private desktopQuery: DesktopQuery,
    private instrumentsQuery: InstrumentsQuery,
    private mainEquipmentQuery: MainEquipmentQuery,
    private activeEquipmentQuery: ActiveEquipmentQuery,
    private appConfig: AppConfig,
    private matSnackBar: MatSnackBar
  ) {}

  private getMainEquipmentInfo(): EquipmentIDN {
    const mainEquipment = this.mainEquipmentQuery.getValue();
    return {
      equipmentModel: mainEquipment.info.model,
      connectionType: mainEquipment.info.connectionType,
      address: mainEquipment.info.address,
    };
  }

  private getExternalScannerInfo(model: string, number: string): EquipmentIDN {
    const externalScanner = this.activeEquipmentQuery.getValue().data.find((x) => {
      return x.info.model === model && x.info.model_number === number;
    });
    return {
      equipmentModel: externalScanner.info.model,
      connectionType: externalScanner.info.connectionType,
      address: externalScanner.info.address,
    };
  }

  active() {
    return this.http.post<EquipmentState>(DesktopApi.active, {
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  manualScanEquipment(override: boolean, setLoading: boolean) {
    if (this._scanning === true) {
      return of(null);
    }

    const notification = this.matSnackBar.openFromComponent(CustomSnackbarComponent, {
      data: {
        message: 'Trying to scan the available instruments...',
        hideCloseBtn: true,
      },
      duration: 0,
    });

    this.setScanning(setLoading);
    this._scanning = true;
    return this.http.get(`${DesktopApi.manualScan}?override=${override}`).pipe(
      catchError((err) => {
        this.setScanning(false);
        return throwError(() => err);
      }),
      finalize(() => notification.dismiss())
    );
  }

  connect(data: EquipmentIDN) {
    let api = data.isEecModel ? DesktopApi.eecConnect : DesktopApi.connect;
    return this.http
      .post(api, {
        equipmentInfo: data,
      })
      .pipe(
        catchError((err) => {
          this.setConnection(false);
          return throwError(() => err);
        })
      );
  }

  disconnect(data: EquipmentIDN) {
    let api = data && data.isEecModel ? DesktopApi.eecDisconnect : DesktopApi.disconnect;
    return this.http.post(api, {
      equipmentInfo: data,
    });
  }

  setMainEquipment(data: EquipmentIDN) {
    return this.http.post(DesktopApi.setMainEquipment, {
      equipmentInfo: data,
    });
  }

  failStop(enable: boolean) {
    return this.http.post(DesktopApi.failStop, {
      enable: enable,
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  singleStep(enable: boolean) {
    return this.http.post(DesktopApi.singleStep, {
      enable: enable,
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  setTest(test: number) {
    return this.http.post(DesktopApi.setTest, {
      test: test,
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  setStep(step: number) {
    return this.http.post(DesktopApi.setStep, {
      step: step,
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  setReportId(id: number) {
    return this.http.post(DesktopApi.setReportId, {
      reportId: id,
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  bypassCommand(command: string, info?: EquipmentIDN) {
    return this.http.post(
      `${DesktopApi.devices}/bypass-command`,
      {
        command: command,
        equipmentInfo: info ? info : this.getMainEquipmentInfo(),
      },
      { responseType: 'text' }
    );
  }

  getTestMemoryType(file: number) {
    return this.http.post(`${DesktopApi.testMemoryType}`, {
      file: file,
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  test(isPrompt: boolean) {
    this.runningSubject.next(true);
    return this.http.post(DesktopApi.runTest, {
      equipmentInfo: this.getMainEquipmentInfo(),
      isPrompt,
    });
  }

  scannerTest(scannerParams: ScannerSetup, isPrompt: boolean) {
    return this.http.post(DesktopApi.runScannerTest, {
      equipmentInfo: this.getMainEquipmentInfo(),
      scannerInfo: this.getExternalScannerInfo(scannerParams.scanner_model, scannerParams.scanner_number),
      isPrompt,
    });
  }

  reset() {
    this.runningSubject.next(false);
    return this.http.post(DesktopApi.reset, {
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  resetTestAllEquipment(allEquipments: any) {
    this.runningSubject.next(false);
    return this.http.post(DesktopApi.resetTestAllEquipment, allEquipments);
  }

  scannerReset(equipmentInfo: Info) {
    return this.http.post(DesktopApi.scannerReset, {
      scannerInfo: this.getExternalScannerInfo(equipmentInfo.model, equipmentInfo.model_number),
    });
  }

  scan() {
    return this.desktopQuery.hostname$.pipe(
      switchMap((host) =>
        this.instrumentsQuery.selectAll().pipe(
          take(1),
          switchMap((list) => {
            return list
              .map((eq) =>
                eq.workstations_attributes.map((station) => ({
                  ...station,
                  equipmentType: eq.equipment_type,
                  id: eq.id,
                }))
              )
              .reduce((acc, curr) => acc.concat(curr), [])
              .filter((station) => station.name.includes(host))
              .reduce((stream, station) => {
                return stream.pipe(
                  catchError(() =>
                    this.connect({
                      equipmentModel: station.equipmentType,
                      connectionType: station.connection_type,
                      address: station.address,
                    }).pipe(
                      switchMap((res: any) =>
                        this.updateEquipmentInfo(station.id, {
                          calibration_date: res.info.calibrationDate,
                          scanner_status: res.info.scannerStatus,
                        }).pipe(
                          mapTo(res),
                          catchError(() => of(res))
                        )
                      )
                    )
                  )
                );
              }, throwError(null));
          })
        )
      )
    );
  }

  remove(id: number): Observable<any> {
    return this.http.delete(`${this.baseApi.equipments}/${id}`);
  }

  public getDevices(equipmentType: string, connectionType: string, isEecModel: boolean): Observable<any[]> {
    return this.http.get<any[]>(DesktopApi.devices, {
      params: {
        equipmentModel: equipmentType,
        connectionType: connectionType,
        isEecModel: isEecModel,
      },
    });
  }

  public logConnected() {
    return this.mainEquipmentQuery.info$.pipe(
      take(1),
      switchMap((info) => {
        if (!info) {
          return of(null);
        }

        return this.http.post(`${this.baseApi.equipments}/connect`, {
          serial_number: info.serial_number,
          scanner_status: info.scannerStatus,
        });
      })
    );
  }

  public refresh() {
    this.runningSubject.next(false);
    return this.http.post(DesktopApi.refreshState, {
      equipmentInfo: this.getMainEquipmentInfo(),
    });
  }

  public setConnection(value: boolean, connection?: any) {
    this.connectingSubject.next(value);
    if (connection) {
      this.connectingObjSubject.next(connection);
    } else {
      this.connectingObjSubject.next(null);
    }
  }

  public setScanning(value: boolean) {
    this.scanningSubject.next(value);
    this._scanning = value;
  }

  updateEquipmentInfo(id: any, body: { calibration_date?: string; scanner_status?: number; name?: string }) {
    return this.http.patch(`${this.baseApi.equipments}/${id}`, { equipment: body });
  }

  checkConnectionsByAddresses(addresses: any[]) {
    return this.http.post<any[]>(DesktopApi.scan, addresses);
  }
}
