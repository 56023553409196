import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EecEquipmentState } from './eec-main-equipment.store';

export interface EecActiveEquipmentState {
  data: EecEquipmentState[];
}

export function createInitialState(): EecActiveEquipmentState {
  return {
    data: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'eec-active-equipment' })
export class EecActiveEquipmentStore extends Store<EecActiveEquipmentState> {
  constructor() {
    super(createInitialState());
  }
}
