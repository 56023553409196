import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  EecEquipmentState,
  EecEquipmentTest,
  EecMainEquipmentStore,
  EecResult,
  EecStep,
} from './eec-main-equipment.store';

export interface EecTestWithResults extends EecEquipmentTest {
  steps: EecStepWithResults[];
}

export interface EecStepWithResults extends EecStep {
  testResult?: EecResult;
}

export interface EquipmentUiActions {
  canSetSingleStep: boolean;
  canSetFailStop: boolean;
  canSelectTest: boolean;
  canTest: boolean;
}

@Injectable({ providedIn: 'root' })
export class EecMainEquipmentQuery extends Query<EecEquipmentState> {
  private $testSuccess = new BehaviorSubject<boolean>(false);
  info$ = this.select('info');
  testProgress$ = this.select('testProgress');

  activeFile$ = this.select((state) => state?.testProgress?.selectedFile);
  activeStep$ = this.select((state) => state?.testProgress?.selectedStep);
  testResults$ = this.select((state) => state?.testProgress?.results);
  overallState$ = this.select((state) => state?.testProgress?.overallStatus);
  testInProgress$ = this.select((state) => state?.testProgress?.testInProgress);
  testFinished$ = this.select((state) => state.testProgress?.testFinished);

  constructor(protected store: EecMainEquipmentStore) {
    super(store);
  }

  public connectTestSuccessChange(): Observable<boolean> {
    return this.$testSuccess.asObservable();
  }

  public testSuccessChange(value: boolean) {
    this.$testSuccess.next(value);
  }
}
