import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EquipmentState, MainEquipmentStore, EquipmentTest } from './main-equipment.store';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap,
  throttleTime,
  toArray,
} from 'rxjs/operators';
import { AuthQuery } from '../auth/state/auth.query';
import { SocketService } from '../services/socket.service';
import { asyncScheduler, from, Subscription, throwError } from 'rxjs';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { CustomSnackbarComponent } from '../shared/custom-snackbar/custom-snackbar.component';
import { CloudApi } from '@app/helpers/cloud.api';
import { DesktopApi } from '../helpers/desktop.api';
import { EquipmentService } from '../services/equipment.service';
import { Instrument } from '../equipment/state/instrument.model';
import { Info } from './main-equipment.store';
import { ActiveEquipmentQuery } from './active-equipment.query';
import { ScannerSetup } from '../services/scanner-setup.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

function isNotEmpty(value: any) {
  return value !== null && value !== undefined && value !== '';
}

@Injectable({ providedIn: 'root' })
export class MainEquipmentService {
  private isDisconnected = true;
  private disconnectionEvent: Subscription;
  private activeUpdateEvent: Subscription;
  private logConnection: Subscription;
  public testInProgress: boolean = false;
  private disconnectTimeout: any;
  private socketHasConnected: boolean = false;
  private sciTestFileStarting: number = null;
  private isRanTheMemoryNextQueue: boolean = false;

  constructor(
    private http: HttpClient,
    private cloudApi: CloudApi,
    private activeEquipmentQuery: ActiveEquipmentQuery,
    private mainEquipmentStore: MainEquipmentStore,
    private authQuery: AuthQuery,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private equipmentService: EquipmentService,
    private translate: TranslateService
  ) {}

  public start() {
    this.subscribeEvents();
    return this.authQuery.loggedIn$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap((loggedIn) => {
        if (!loggedIn) {
          this.socketService.disconnect();
          // this.disconnect(null);
          this.mainEquipmentStore.reset();
        }
      })
    );
  }

  public checkConnectionEnabled(data: Instrument[]) {
    const active = this.mainEquipmentStore.getValue();
    if (active?.info) {
      const found = data.find((instrument) => instrument.serial_number === active?.info?.serial_number);
      if (!found) {
        this.disconnect(null);
      }
    }
  }

  private stop() {
    this.mainEquipmentStore.reset();
    this.disconnectionEvent?.unsubscribe();
    this.activeUpdateEvent?.unsubscribe();
    this.logConnection?.unsubscribe();
    this.isDisconnected = true;
  }

  private subscribeEvents() {
    this.socketService.connected$.subscribe((connected) => {
      if (!connected) {
        if (!this.disconnectTimeout && this.socketHasConnected === true) {
          this.disconnectTimeout = setTimeout(() => {
            this.stop();
          }, 30 * 1000);
        }
      } else {
        if (this.disconnectTimeout) {
          clearTimeout(this.disconnectTimeout);
          this.disconnectTimeout = null;
        }
        this.socketHasConnected = true;
        this.processSocketConnection();
      }
    });
  }

  private processSocketConnection() {
    if (this.isDisconnected === false) {
      return;
    }
    this.isDisconnected = false;
    this.connectionEvents();
  }

  private connectionEvents() {
    this.disconnectionEvent = this.socketService.on(['main-equipment:disconnected']).subscribe((data) => {
      this.instrumentDisconnected();
    });
    this.activeUpdateEvent = this.socketService
      .on(['main-equipment:connected', 'main-equipment:changes'])
      .pipe(throttleTime(environment.throttleTimeDelay, asyncScheduler, { leading: true, trailing: true }))
      .subscribe((data) => {
        this.mainEquipmentStore.update(data);
      });
    this.logConnection = this.socketService
      .on(['main-equipment:connected'])
      .pipe(filter((data) => !!data))
      .subscribe((data) => {
        this.instrumentConnected(data);
      });
  }

  private instrumentDisconnected() {
    const equipment = this.mainEquipmentStore.getValue().info;
    if (equipment) {
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        data: this.translate.instant('activeEquipment.instrumentActiveDisconnected', {
          model: equipment.model,
          model_number: equipment.model_number,
          serial_number: equipment.serial_number,
        }),
        duration: 5000,
      });
      this.mainEquipmentStore.reset();
      this.replaceMainEquipment();
    }
  }

  private replaceMainEquipment() {
    const indexChange = this.activeEquipmentQuery.getValue().data.findIndex((v: EquipmentState) => {
      return v.info.model !== 'Multiplexer';
    });
    if (indexChange >= 0) {
      const equipmentChange = this.activeEquipmentQuery.getValue().data[indexChange];
      const equipmentInfo = {
        equipmentModel: equipmentChange.info.model,
        connectionType: equipmentChange.info.connectionType,
        address: equipmentChange.info.address,
      };
      this.mainEquipmentStore.update(equipmentChange);
      this.equipmentService
        .setMainEquipment(equipmentInfo)
        .toPromise()
        .then((value) => console.log('Set Main Equipment: ', value));
    }
  }

  private instrumentConnected(equipment: EquipmentState) {
    const info = equipment.info;
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: this.translate.instant('activeEquipment.instrumentActiveConnected', {
        model: info.model,
        model_number: info.model_number,
        serial_number: info.serial_number,
      }),
      duration: 5000,
    });
  }

  storeConnectionCatchError(err) {
    this.equipmentService.setConnection(false);
    return throwError(err);
  }

  private disconnect(data: { equipmentModel: string; connectionType: string; address: string }) {
    this.equipmentService.disconnect(data).subscribe();
  }

  public setFailStop(enabled: boolean) {
    return this.equipmentService.failStop(enabled);
  }

  public setSingleStep(enabled: boolean, canSwitch: boolean = false) {
    this.testInProgress = canSwitch;
    return this.equipmentService.singleStep(enabled);
  }

  public setTest(test: number) {
    return this.equipmentService.setTest(test);
  }

  public setStep(step: number) {
    return this.equipmentService.setStep(step);
  }

  public runTest(isPrompt: boolean) {
    return this.equipmentService.test(isPrompt);
  }

  public runScannerTest(scannerSetup: ScannerSetup, isPrompt: boolean) {
    return this.equipmentService.scannerTest(scannerSetup, isPrompt);
  }

  public resetTest() {
    return this.equipmentService.reset();
  }

  public resetTestAllEquipment(allEquipments: any) {
    return this.equipmentService.resetTestAllEquipment(allEquipments);
  }

  public scannerResetTest(equipmentInfo: Info) {
    return this.equipmentService.scannerReset(equipmentInfo);
  }

  public setReportId(id: any) {
    return this.equipmentService.setReportId(id);
  }

  setSCITestFileStartingPoint(value: number) {
    this.sciTestFileStarting = value;
  }

  getSCITestFileStartingPoint(): number {
    return this.sciTestFileStarting;
  }

  setRanTheMemoryNextQueue(value: boolean) {
    this.isRanTheMemoryNextQueue = value;
  }

  getRanTheMemoryNextQueue(): boolean {
    return this.isRanTheMemoryNextQueue;
  }
}
