import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, filter, tap, throttleTime } from 'rxjs/operators';
import { AuthQuery } from '../auth/state/auth.query';
import { SocketService } from '../services/socket.service';
import { asyncScheduler, Subscription, throwError } from 'rxjs';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { CustomSnackbarComponent } from '../shared/custom-snackbar/custom-snackbar.component';
import { Instrument } from '../equipment/state/instrument.model';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from '../ui/confirm-click/confirm-dialog/confirm-dialog.component';
import { environment } from '../../environments/environment';
import { TestsService } from '../services/tests.service';
import { BaseApi } from '@ik/shared-data/base.api';
import { TranslateService } from '@ngx-translate/core';
import { EecEquipmentState, EecMainEquipmentStore } from './eec-main-equipment.store';
import { EecActiveEquipmentQuery } from './eec-active-equipment.query';
import { EecActiveEquipmentState, EecActiveEquipmentStore } from './eec-active-equipment.store';
import { EecEquipmentService } from '@app/services/eec-equipment.service';
import { EquipmentIDN } from '@ik/shared-data/schema';

@Injectable({ providedIn: 'root' })
export class EecActiveEquipmentService {
  private isDisconnected = true;
  private disconnectionEvent: Subscription;
  private activeUpdateEvent: Subscription;
  private logConnection: Subscription;
  private disconnectTimeout: any;
  private socketHasConnected: boolean = false;
  private loggedIn: boolean = true;

  constructor(
    private eecMainEquipmentStore: EecMainEquipmentStore,
    private eecActiveEquipmentStore: EecActiveEquipmentStore,
    private eecEquipmentService: EecEquipmentService,
    private authQuery: AuthQuery,
    private socketService: SocketService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private baseApi: BaseApi,
    private translate: TranslateService
  ) {}

  public start() {
    this.subscribeEvents();
    return this.authQuery.loggedIn$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(async (loggedIn) => {
        if (!loggedIn) {
          this.loggedIn = false;
          this.socketService.disconnect();
          this.disconnect(null);
          this.eecActiveEquipmentStore.reset();
        } else {
          this.loggedIn = true;
        }
      })
    );
  }

  public checkConnectionEnabled(data: Instrument[]) {
    const active = this.eecActiveEquipmentStore.getValue().data;
    if (active.length > 0) {
      let found = null;
      active?.forEach(
        (v: EecEquipmentState) => (found = data.find((instrument) => instrument.serial_number === v.info.serial_number))
      );
      if (!found) {
        this.disconnect(null);
      }
    }
  }

  private stop() {
    this.eecActiveEquipmentStore.reset();
    this.disconnectionEvent?.unsubscribe();
    this.activeUpdateEvent?.unsubscribe();
    this.logConnection?.unsubscribe();
    this.isDisconnected = true;
    this.socketHasConnected = false;
  }

  private confirmDialog(title, text) {
    const dialogRef = this.dialog.open<ConfirmDialogComponent>(ConfirmDialogComponent, {
      data: {
        title,
        text,
      },
      width: '500px',
    } as MatDialogConfig<ConfirmDialogData>);
    return dialogRef.afterClosed();
  }

  private subscribeEvents() {
    this.socketService.connected$.subscribe((connected) => {
      if (!connected) {
        if (!this.disconnectTimeout && this.socketHasConnected === true && this.loggedIn === true) {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: this.translate.instant('activeEquipment.serviceConnectionLost'),
            duration: 30 * 1000,
          });

          this.disconnectTimeout = setTimeout(() => {
            this.stop();

            this.confirmDialog(
              this.translate.instant('activeEquipment.serviceConnectionLostTitle'),
              this.translate.instant('activeEquipment.serviceConnectionLostMessage')
            );
          }, 30 * 1000);
        } else {
          this.stop();
        }
      } else {
        if (this.disconnectTimeout) {
          clearTimeout(this.disconnectTimeout);
          this.disconnectTimeout = null;
        }

        this.socketHasConnected = true;
        this.processSocketConnection();
      }
    });
  }

  private processSocketConnection() {
    if (this.isDisconnected === false) {
      return;
    }
    this.isDisconnected = false;
    this.connectionEvents();
  }

  private connectionEvents() {
    this.disconnectionEvent = this.socketService
      .on(['eec-active-equipment:disconnected'])
      .subscribe((data: EecEquipmentState) => {
        const index = this.eecActiveEquipmentStore.getValue().data.findIndex((v: EecEquipmentState) => {
          return (
            v.info.model === data.info.model &&
            v.info.model_number === data.info.model_number &&
            v.info.serial_number === data.info.serial_number &&
            v.info.connectionType === data.info.connectionType &&
            v.info.address === data.info.address
          );
        });
        if (index > -1) {
          this.eecActiveEquipmentStore.getValue().data.splice(index, 1);
          this.instrumentDisconnected(data);
        }
      });

    this.activeUpdateEvent = this.socketService
      .on(['eec-active-equipment:connected', 'eec-active-equipment:changes'])
      .pipe(throttleTime(environment.throttleTimeDelay, asyncScheduler, { leading: true, trailing: true }))
      .subscribe((data: any) => {
        if (data.hasOwnProperty('data')) {
          this.eecActiveEquipmentStore.update(data);
        } else {
          const activeEquipment: EecEquipmentState = data;
          let store: { data: any[] } = { data: [] };
          this.eecActiveEquipmentStore.getValue().data.forEach((v) => store.data.push(v));
          let index = store.data.findIndex((v: EecEquipmentState) => {
            return (
              v.info.model === activeEquipment.info.model &&
              v.info.model_number === activeEquipment.info.model_number &&
              v.info.serial_number === activeEquipment.info.serial_number &&
              v.info.connectionType === activeEquipment.info.connectionType &&
              v.info.address === activeEquipment.info.address
            );
          });

          if (index < 0) {
            index = store.data.length;
            store.data[index] = {};
          }

          if (data.hasOwnProperty('info')) {
            store.data[index]['info'] = activeEquipment.info;
          }
          if (data.hasOwnProperty('testProgress')) {
            store.data[index]['testProgress'] = activeEquipment.testProgress;
          }

          if (!this.eecMainEquipmentStore.getValue().info) {
            this.eecMainEquipmentStore.update(store.data[index]);
            const equipmentInfo = {
              equipmentModel: store.data[index]['info']['model'],
              connectionType: store.data[index]['info']['connectionType'],
              address: store.data[index]['info']['address'],
            };
            this.eecEquipmentService
              .setMainEquipment(equipmentInfo)
              .toPromise()
              .then((value) => console.log('Set Main Equipment: ', value));
          }
          this.eecActiveEquipmentStore.update(store);
        }

        this.eecEquipmentService.setConnection(false);
      });

    this.logConnection = this.socketService
      .on(['eec-active-equipment:connected'])
      .pipe(filter((data) => !!data))
      .subscribe((data: any) => {
        if (data.hasOwnProperty('data')) {
          const activeEquipments: EecActiveEquipmentState = data;
          activeEquipments.data.forEach((v: EecEquipmentState) => this.instrumentConnected(v));
        } else {
          const activeEquipment: EecEquipmentState = data;
          this.instrumentConnected(activeEquipment);
        }
      });
  }
  private instrumentDisconnected(equipment: EecEquipmentState) {
    const info = equipment.info;
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: this.translate.instant('activeEquipment.instrumentDisconnected', {
        model: info.model,
        model_number: info.model_number,
        serial_number: info.serial_number,
      }),
      duration: 5000,
    });
  }

  private instrumentConnected(equipment: EecEquipmentState) {
    this.logConnected(equipment.info.serial_number).subscribe();
    const info = equipment.info;
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: this.translate.instant('activeEquipment.instrumentConnected', {
        model: info.model,
        model_number: info.model_number,
        serial_number: info.serial_number,
      }),
      duration: 5000,
    });
  }

  private logConnected(serialNumber: string) {
    // TODO: Use `${this.baseApi.eecEquipments}/connect` when support full function eec model
    return this.http.post(`${this.baseApi.equipments}/connect`, {
      serial_number: serialNumber,
      scanner_status: 0,
    });
  }

  storeConnectionCatchError(err) {
    this.eecEquipmentService.setConnection(false);
    return throwError(err);
  }

  private disconnect(data: { equipmentModel: string; connectionType: string; address: string }) {
    this.eecEquipmentService.disconnect(data).subscribe();
  }

  public setTest() {
    return this.eecEquipmentService.setTest();
  }

  public resetTest() {
    return this.eecEquipmentService.reset();
  }
}
